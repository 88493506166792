<script setup>
defineProps({})

let submitted = ref(false)
let submitting = ref(false)
let error = ref(false)

let name = ref('')
let company = ref('')
let phone = ref('')
let email = ref('')
let comment = ref('')

let designStore = useDesignStore()

let submittedRef = ref()

let config = useRuntimeConfig()

async function onSubmit(event) {
  event.preventDefault()

  submitting.value = true
  error.value = false

  let data = {
    name: name.value,
    company: company.value,
    phone: phone.value,
    email: email.value,
    message: comment.value,
  }

  let urlBase = new URL(config.public.phpBaseUrl, window.location.origin)
  let url = new URL([urlBase.pathname.replace(/\/$/, ''), 'contact.php'].join('/'), urlBase)

  try {
    let response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: urlBase.origin === window.location.origin ? 'same-origin' : 'include',
      body: JSON.stringify(data),
    })

    if (!response.ok || response.status !== 201) {
      throw new Error('Failed to submit')
    }

    submitted.value = true

    designStore.setScoped({
      background: designStore.tokens.black,
    })

    nextTick(() => {
      submittedRef.value.scrollIntoView({ block: 'start', behavior: 'smooth' })
    })
  } catch (submitError) {
    error.value = true
    console.error(submitError)
  } finally {
    submitting.value = false
  }
}
</script>

<template>
  <div v-if="!submitted">
    <!-- Intro -->
    <div class="style-intro">
      <div class="">
        <h1>
          Du bist dran. Erzähl uns von deiner Idee. Und lass uns zusammen etwas Grosses kreieren.
        </h1>
      </div>
    </div>

    <!-- Main -->
    <div class="">
      <div class="">
        <Form v-slot="{ valid }" @submit="onSubmit">
          <div class="form-grid" :class="{ submitting }">
            <Input v-model="name" :disabled="submitting" label="Name" required />
            <Input v-model="company" :disabled="submitting" label="Unternehmen" required />

            <Input v-model="phone" type="tel" :disabled="submitting" required>
              <span class="contact-phone-label">Telefon</span>

              <!-- prettier-ignore -->
              <svg class="contact-phone-icon" aria-hidden="true" focusable="false" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.482 3.671C6.274 1.274 3.774.098 3.67.051a.523.523 0 0 0-.317-.043C.467.488.032 2.168.015 2.236a.555.555 0 0 0 .01.296C3.469 13.214 10.62 15.194 12.97 15.846c.18.05.33.09.443.127a.542.542 0 0 0 .394-.023c.072-.032 1.77-.833 2.186-3.445a.551.551 0 0 0-.052-.334c-.037-.072-.926-1.764-3.39-2.362a.533.533 0 0 0-.482.115c-.778.665-1.851 1.372-2.315 1.445-3.106-1.52-4.84-4.436-4.906-4.989-.038-.31.674-1.403 1.492-2.291a.546.546 0 0 0 .142-.418" fill="#FFF" fill-rule="evenodd"/></svg>
            </Input>

            <Input v-model="email" type="email" :disabled="submitting" required>
              <span class="contact-email-label">E-Mail</span>

              <!-- prettier-ignore -->
              <svg class="contact-email-icon" aria-hidden="true" focusable="false" width="16" height="12" viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg"><path d="M15 0H1a1 1 0 0 0-1 1v1.68l8 3.199 8-3.2V1A1 1 0 0 0 15 0ZM0 4.312V11a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4.312l-8 3.2-8-3.2Z" fill="#FFF" fill-rule="evenodd"/></svg>
            </Input>

            <Input
              v-model="comment"
              :disabled="submitting"
              class="contact-comment"
              label="Dein Projekt in einigen Sätzen"
              multiline
              required
            />

            <div class="submit-line">
              <p>Bereit für etwas Grosses?</p>
              <Button
                type="submit"
                class="submit-button"
                :class="{ 'submit-valid': valid }"
                with-arrow
              >
                {{ submitting ? 'Versand läuft...' : 'Ab die Post!' }}
              </Button>
            </div>
          </div>

          <p v-if="error" class="submit-error">
            Fehler beim Versand des Formulars. Bitte versuchen Sie es noch einmal.<br />
            Sollte dieses Problem wiederholt auftreten, wenden Sie sich bitte direkt per E-Mail an
            uns.
          </p>
        </Form>
      </div>
    </div>
  </div>
  <div v-else>
    <h2 ref="submittedRef" class="sent-sub-headline">Du hörst von uns!</h2>
    <h1 class="sent-headline">
      <span class="sent-headline-primary">See you</span>
      <span class="sent-headline-secondary">soon!</span>
    </h1>

    <ArrowLink href="/" class="sent-continue-link"> Weiterstöbern </ArrowLink>
  </div>
</template>

<style lang="scss" scoped>
.form-grid {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr;
  grid-gap: 3rem 80px;
  justify-content: flex-start;

  @include mediaSM {
    grid-template-columns: 1fr 1fr;
  }

  &.submitting {
    opacity: 0.6;
  }
}

.style-intro {
  padding-bottom: 1rem;
}

.contact-phone-label,
.contact-email-label {
  margin-right: calc(26px + 0.65em);
}

.contact-phone-icon,
.contact-email-icon {
  position: absolute;
  width: 26px;
  height: auto;
  margin-left: -26px;
}

.contact-phone-icon {
  margin-top: -0.15em;
}

.contact-email-icon {
  margin-top: 0.05em;
}

.contact-comment {
  grid-column: 1 / -1;
}

.submit-line {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-column: 1 / -1;
  grid-gap: 2rem;

  @include mediaSM {
    flex-direction: row;
    align-items: center;
  }

  p {
    font-weight: bold;
    margin: 0;
  }
}

.submit-button {
  &.submit-valid {
    color: var(--input-text-color);
  }
}

.sent-headline {
  font-size: min(25vw, 200px);
  line-height: 0.9;
}

.sent-sub-headline {
  scroll-margin-top: calc(56px + 2rem); // Menu button height + margin
  font-size: min(15vw, 70px);
}

.sent-headline-primary {
  color: var(--primary-color);
}

.sent-headline-secondary {
  display: block;
}

.sent-continue-link {
  font-size: 30px;
}

.submit-error {
  color: var(--form-error);
  font-weight: bold;
}
</style>
