<script>
export default {
  inheritAttrs: false,
}
</script>

<script setup>
let props = defineProps({
  src: String,
  autoplay: {
    type: [Boolean, String],
    default: false,
    validator: v => ['intersect', true, false].includes(v),
  },
  muted: Boolean,
  loop: Boolean,
  fitVideo: Boolean,
  showControls: {
    type: [Boolean, String],
    default: 'hover',
    validator: v => ['never', 'interaction', 'hover', 'always', false].includes(v),
  },
  disableSurfaceControls: Boolean,
  disableFullscreen: Boolean,
  disableVolumeControl: Boolean,
  disableMuteControl: Boolean,
  disablePlayingControl: Boolean,
  disableProgressControl: Boolean,
  disableKeyboardControls: Boolean,
  hideProgress: Boolean,
  idleTimer: {
    type: Number,
    default: 2500,
  },
})

let emit = defineEmits(['update:idle', 'update:playing'])

let allControlsHidden = computed(
  () =>
    props.showControls === false ||
    props.showControls === 'never' ||
    (props.hideProgress &&
      props.disableFullscreen &&
      props.disableVolumeControl &&
      props.disableMuteControl &&
      props.disablePlayingControl),
)

let videoContainerRef = ref()
let videoRef = ref()
let playerSize = useElementSize(videoContainerRef)
let isLarge = computed(() => playerSize.width.value >= 1600 && playerSize.height.value >= 900)

let containerFullscreen = useFullscreen(videoContainerRef)
let videoFullscreen = useFullscreen(videoRef)
let fullscreenTarget = computed(() =>
  containerFullscreen.isSupported.value ? videoContainerRef.value : videoRef.value,
)
let fullscreen = useFullscreen(fullscreenTarget)

let mediaControls = useMediaControls(videoRef, {
  src: toRef(props, 'src'),
})

onMounted(() => {
  videoRef.value.muted = props.muted
})

let pausedManually = ref(false)
function onPlayPauseControl() {
  pausedManually.value = true
  mediaControls.playing.value = !mediaControls.playing.value
}

function onMuteControl() {
  mediaControls.muted.value = !mediaControls.muted.value
}

defineExpose({
  mediaControls: markRaw(mediaControls),
  pausedManually,
})

useSingleClick(videoRef, () => {
  if (props.showControls === 'never') return
  if (props.disableSurfaceControls && !fullscreen.isFullscreen.value) return

  onPlayPauseControl()
})

function onSpaceKey() {
  if (props.showControls === 'never') return
  if (props.disableKeyboardControls && !fullscreen.isFullscreen.value) return

  onPlayPauseControl()
}

function onLeftKey() {
  if (props.showControls === 'never') return
  if (props.disableProgressControl) return
  if (props.disableKeyboardControls && !fullscreen.isFullscreen.value) return

  mediaControls.currentTime.value = Math.max(mediaControls.currentTime.value - 5, 0)
}

function onRightKey() {
  if (props.showControls === 'never') return
  if (props.disableProgressControl) return
  if (props.disableKeyboardControls && !fullscreen.isFullscreen) return

  mediaControls.currentTime.value = Math.min(
    mediaControls.currentTime.value + 5,
    mediaControls.duration.value,
  )
}

function onSurfaceDoubleclick() {
  if (props.showControls === 'never') return
  if (props.disableSurfaceControls && !fullscreen.isFullscreen.value) return
  if (props.disableFullscreen) return
  fullscreen.toggle()
}

function onScrub() {
  mediaControls.muted.value = false
}

if (props.muted) {
  mediaControls.muted.value = true
}

onMounted(() => {
  if (props.autoplay === true) {
    mediaControls.muted.value = true

    // Need to wait for the video to actually be muted because otherwise
    // the autoplay will be blocked by the browser
    nextTick(() => {
      mediaControls.playing.value = true
    })
  }
})

if (props.autoplay === 'intersect') {
  let { stop } = useIntersectionObserver(
    videoContainerRef,
    ([{ isIntersecting }]) => {
      if (isIntersecting) {
        mediaControls.muted.value = true
        mediaControls.playing.value = true

        setTimeout(() => {
          stop()
        }, 0)
      }
    },
    {
      threshold: 0.65,
    },
  )
}

watch(fullscreen.isFullscreen, isFullscreen => {
  if (!isFullscreen) {
    // Focus container if just left fullscreen
    videoContainerRef.value.focus({ preventScroll: true })
  }
})

// TODO: Instead of syncing 'idle', make a 'controls-showing' state
// This will require moving away from CSS :hover towards JS-based hover detection
let { idle } = useIdle(props.idleTimer)
watch(idle, isIdle => {
  emit('update:idle', isIdle)
})
watch(
  mediaControls.playing,
  isPlaying => {
    emit('update:playing', isPlaying)
  },
  { immediate: true },
)

let scrubbing = ref(false)
let wasPlaying = false
watch(scrubbing, isScrubbing => {
  if (isScrubbing) {
    wasPlaying = mediaControls.playing.value
    mediaControls.playing.value = false
  } else {
    mediaControls.playing.value = wasPlaying
  }
})
</script>

<template>
  <div
    ref="videoContainerRef"
    class="video-container"
    :class="[
      {
        scrubbing,
        'fit-video': fitVideo,
        large: isLarge,
        fullscreen: fullscreen.isFullscreen.value,
        playing: mediaControls.playing.value,
        idle: (showControls === 'interaction' || showControls === 'hover') && idle,
        loading: mediaControls.waiting.value,
        ended:
          mediaControls.duration.value > 0 &&
          mediaControls.currentTime.value === mediaControls.duration.value,
      },
      $attrs.class,
    ]"
    :style="$attrs.style"
    @keydown.space.prevent="onSpaceKey"
    @keydown.left.prevent="onLeftKey"
    @keydown.right.prevent="onRightKey"
    tabindex="0"
  >
    <div class="video-grid">
      <video
        ref="videoRef"
        class="video"
        @dblclick="onSurfaceDoubleclick"
        v-bind="reactiveOmit($attrs, 'class', 'style')"
        disablepictureinpicture
        disableremoteplayback
        playsinline="true"
        :loop="loop"
      />
      <!-- prettier-ignore -->
      <div v-if="mediaControls.waiting.value" class="video-spinner-container">
        <svg aria-hidden="true" focusable="false" class="video-spinner" width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a"><stop stop-color="#fff" stop-opacity="0" offset="0%"/><stop stop-color="#fff" stop-opacity=".631" offset="63.146%"/><stop stop-color="#fff" offset="100%"/></linearGradient></defs><g transform="translate(1 1)" fill="none" fill-rule="evenodd"><path d="M36 18c0-9.94-8.06-18-18-18" stroke="url(#a)" stroke-width="2"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite"/></path><circle fill="#fff" cx="36" cy="18" r="1"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite"/></circle></g></svg>
        <span class="sr-only">Video is loading...</span>
      </div>
      <div
        v-show="!allControlsHidden"
        class="video-controls"
        :class="{
          fullscreen: fullscreen.isFullscreen.value,
          playing: mediaControls.playing.value,
          'force-show': showControls === 'always',
          'show-on-hover': showControls === 'hover' && !fullscreen.isFullscreen.value,
          'show-on-interaction':
            showControls === 'interaction' ||
            (showControls === 'hover' && fullscreen.isFullscreen.value),
          idle,
        }"
        @keydown.stop
      >
        <Scrubber
          v-show="!hideProgress"
          class="controls-progress-scrubber"
          v-model="mediaControls.currentTime.value"
          :max="mediaControls.duration.value"
          v-model:scrubbing="scrubbing"
          :inert="disableProgressControl"
        />
        <div class="controls-playstate">
          <button
            v-show="!disablePlayingControl"
            class="controls-button controls-play-pause"
            @click="onPlayPauseControl"
          >
            <template v-if="mediaControls.playing.value">
              <!-- prettier-ignore -->
              <svg aria-hidden="true" focusable="false" width="10" height="11" viewBox="0 0 10 11" xmlns="http://www.w3.org/2000/svg"><path d="M3.192 11H.808C.361 11 0 10.647 0 10.228V.77C0 .342.361 0 .808 0h2.384C3.639 0 4 .342 4 .771v9.457c0 .42-.361.772-.808.772Zm6 0H6.807C6.362 11 6 10.647 6 10.228V.77C6 .342 6.362 0 6.808 0H9.19c.447 0 .809.342.809.771v9.457c0 .42-.362.772-.809.772Z" fill="#FFF" fill-rule="evenodd"/></svg>
              <span class="sr-only">Pause</span>
            </template>
            <template
              v-else-if="
                !(
                  mediaControls.duration.value > 0 &&
                  mediaControls.currentTime.value === mediaControls.duration.value
                )
              "
            >
              <!-- prettier-ignore -->
              <svg aria-hidden="true" focusable="false" width="10" height="11" viewBox="0 0 10 11" xmlns="http://www.w3.org/2000/svg"><path d="M9.04 4.68c.614.36.614 1.27 0 1.64L5.21 8.591l-3.828 2.28C.768 11.23 0 10.78 0 10.05V.95C0 .22.768-.24 1.382.13l3.828 2.27 3.83 2.28" fill="#FFF" fill-rule="evenodd"/></svg>
              <span class="sr-only">Play</span>
            </template>
            <template v-else>
              <!-- prettier-ignore -->
              <svg aria-hidden="true" focusable="false" width="15" height="16" viewBox="0 0 15 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.987 16c-1.769 0-3.302-.565-4.599-1.694C1.092 13.176.3 11.769.014 10.084a.925.925 0 0 1 .241-.807c.21-.227.488-.34.834-.34.299 0 .565.104.798.313.233.21.397.482.493.816.25 1.028.8 1.885 1.649 2.572s1.835 1.031 2.958 1.031c1.315 0 2.432-.463 3.352-1.389.92-.926 1.38-2.047 1.38-3.36 0-1.316-.463-2.43-1.39-3.345-.925-.914-2.045-1.371-3.36-1.371h-.09l.323.322c.203.204.302.42.296.646-.006.227-.105.436-.296.627a.89.89 0 0 1-.627.278.882.882 0 0 1-.646-.26L3.993 3.863a1.21 1.21 0 0 1-.27-.394 1.113 1.113 0 0 1-.089-.43c0-.144.03-.287.09-.43.06-.144.15-.275.27-.395L5.91.296A.942.942 0 0 1 6.575 0a.803.803 0 0 1 .645.296.806.806 0 0 1 .278.628.93.93 0 0 1-.296.645l-.305.305h.072c.968 0 1.882.182 2.743.546a7.157 7.157 0 0 1 3.8 3.765 6.8 6.8 0 0 1 .556 2.734 6.86 6.86 0 0 1-.556 2.742 7.155 7.155 0 0 1-3.782 3.783c-.86.37-1.775.556-2.743.556Z" fill="#FFF" fill-rule="nonzero"/></svg>
              <span class="sr-only">Repeat</span>
            </template>
          </button>
          <button
            v-show="!disableMuteControl"
            class="controls-button controls-muted"
            @click="onMuteControl"
          >
            <template v-if="!mediaControls.muted.value">
              <!-- prettier-ignore -->
              <svg aria-hidden="true" focusable="false" width="17" height="16" viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg"><path d="M10.853 15.735c-.363.129-.703.07-1.02-.175a1.188 1.188 0 0 1-.476-.99c0-.181.055-.346.165-.495a.914.914 0 0 1 .437-.32 5.936 5.936 0 0 0 3-2.262 6.166 6.166 0 0 0 1.136-3.622c0-1.32-.382-2.52-1.146-3.6-.764-1.081-1.76-1.829-2.99-2.243a.922.922 0 0 1-.437-.32.816.816 0 0 1-.165-.496c0-.4.159-.724.476-.97.317-.246.65-.304 1-.175 1.592.583 2.89 1.586 3.893 3.01C15.729 4.5 16.23 6.098 16.23 7.87c0 1.787-.495 3.395-1.485 4.825-.99 1.43-2.287 2.444-3.892 3.039Zm-9.805-5.106c-.285 0-.53-.104-.738-.311A1.007 1.007 0 0 1 0 9.58V6.24c0-.285.103-.53.31-.738.207-.207.453-.31.738-.31h2.214l2.756-2.699c.337-.336.719-.417 1.146-.243.427.175.64.495.64.96v9.398c0 .466-.213.786-.64.961-.427.175-.81.094-1.146-.243l-2.756-2.698H1.048Zm8.388.601v-6.6c.698.336 1.222.79 1.572 1.359.35.57.524 1.216.524 1.941 0 .725-.175 1.372-.524 1.941-.35.57-.874 1.023-1.572 1.359ZM5.669 5.794 4.097 7.328H2.135v1.165h1.962l1.572 1.534V5.794Z" fill="#FFF" fill-rule="nonzero"/></svg>
              <span class="sr-only">Mute</span>
            </template>
            <template v-else>
              <!-- prettier-ignore -->
              <svg width="17" height="16" viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg"><path d="m13.194 15.659-.834-.815c-.24.163-.49.305-.75.426-.259.12-.534.23-.824.332-.355.126-.684.063-.987-.19a1.203 1.203 0 0 1-.456-.967c0-.178.054-.336.161-.475a.919.919 0 0 1 .427-.303c.14-.051.285-.108.437-.171.151-.063.297-.133.437-.209L7.75 10.233v2.182c0 .455-.209.768-.626.939-.418.17-.79.091-1.12-.237L3.33 10.499H1.167a.986.986 0 0 1-.721-.304.984.984 0 0 1-.304-.72V6.174c0-.28.101-.52.304-.722a.987.987 0 0 1 .72-.303h1.5L.333 2.815c-.229-.228-.34-.477-.333-.75.006-.271.123-.521.35-.749C.58 1.09.829.975 1.1.975s.522.114.75.341l12.843 12.882c.215.215.322.455.322.721 0 .265-.107.512-.322.74-.215.227-.461.341-.74.341-.277 0-.53-.114-.759-.341ZM10.786.064c1.618.544 2.93 1.528 3.936 2.95 1.006 1.424 1.508 3.02 1.508 4.79 0 .696-.085 1.38-.256 2.05a7.665 7.665 0 0 1-.75 1.878l-1.574-1.575c.151-.366.272-.746.36-1.138.089-.392.133-.797.133-1.215a5.93 5.93 0 0 0-1.176-3.595A5.99 5.99 0 0 0 9.93 2a.895.895 0 0 1-.427-.312.797.797 0 0 1-.16-.485c0-.392.151-.711.455-.958.303-.246.632-.306.987-.18Zm.683 7.912L9.343 5.85V4.656a3.582 3.582 0 0 1 1.537 1.27c.393.57.589 1.202.589 1.898v.152Zm-3.72-3.738L6.006 2.493v.019c.328-.33.701-.405 1.12-.228.416.178.625.494.625.95v1.004ZM5.664 9.892V8.146l-.91-.91H2.229v1.176h1.916l1.518 1.48Z" fill="#FFF" fill-rule="nonzero"/></svg>
              <span class="sr-only">Unmute</span>
            </template>
          </button>
          <Scrubber
            v-show="!disableVolumeControl"
            class="controls-volume-scrubber"
            v-model="mediaControls.volume.value"
            :max="1"
            @input="onScrub"
          />
        </div>
        <div class="controls-settings">
          <button
            v-if="!disableFullscreen"
            class="controls-button controls-fullscreen"
            @click="fullscreen.isFullscreen.value ? fullscreen.exit() : fullscreen.enter()"
          >
            <!-- prettier-ignore -->
            <svg aria-hidden="true" focusable="false" width="16" height="11" viewBox="0 0 16 11" xmlns="http://www.w3.org/2000/svg"><g fill="#FFF" fill-rule="evenodd"><polyline points="1.39 4.25 0 4.25 0 0 6.69 0 6.69 1.38 1.39 1.38 1.39 4.25"/><polyline points="16 4.25 14.61 4.25 14.61 1.38 9.31 1.38 9.31 0 16 0 16 4.25"/><polyline points="16 10.999 9.31 10.999 9.31 9.609 14.61 9.609 14.61 6.75 16 6.75 16 10.999"/><polyline points="6.69 10.999 0 10.999 0 6.75 1.39 6.75 1.39 9.609 6.69 9.609 6.69 10.999"/></g></svg>
            <span class="sr-only">{{
              fullscreen.isFullscreen.value ? 'Leave Fullscreen' : 'Enter Fullscreen'
            }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.video-container {
  display: flex;
  align-items: center;

  &.fullscreen.idle.playing {
    cursor: none;
  }

  &:focus {
    outline: none;
  }
}

.video {
  width: 100%;
  height: auto;
  transition: opacity 600ms;
  align-self: center;

  @at-root .video-container.ended &,
    .video-container.loading & {
    opacity: 0.6;
  }

  @at-root .video-container:not(.fullscreen).fit-video & {
    height: 100%;
    object-fit: cover;
    min-height: 0;
  }
}

.video-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  & > * {
    grid-row: 1 / -1;
    grid-column: 1 / -1;
  }

  @at-root .video-container.fit-video & {
    height: 100%;
  }
}

.video-spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-controls {
  align-self: flex-end;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  z-index: 1;
  background-color: rgb(0 0 0 / 30%);
  padding: 10px 10px 5px;
  transition: opacity 300ms;
  user-select: none;

  @at-root .video-container.large & {
    padding: 20px 20px 10px;
    row-gap: 5px;
  }

  &:where(.force-show, .scrubbing) {
    opacity: 1 !important;
  }

  &.show-on-interaction.playing {
    opacity: 0;
    transition-delay: 300ms;

    &:not(.idle) {
      opacity: 1;
      transition-delay: 0ms;
    }
  }

  @include can-hover {
    &.show-on-hover.playing:not(.fullscreen) {
      opacity: 0;
      transition-delay: 300ms;

      @at-root .video-grid:hover .video-controls.show-on-hover.playing:not(.fullscreen) {
        opacity: 1;
        transition-delay: 0ms;
      }
    }
  }

  .controls-progress-scrubber {
    grid-row: 1 / span 1;
    grid-column: 1 / span 2;

    @include can-not-hover {
      --scrubber-margin: 0 0 5px;
    }
  }

  .controls-volume-scrubber {
    --scrubber-width: 100px;
    align-self: center;
    --scrubber-active-color: #fff;
    --scrubber-inactive-color: #fff5;
  }

  .controls-playstate {
    grid-row: 2 / span 1;
    grid-column: 1 / span 1;
    display: flex;
  }

  .controls-button {
    @include reset-form-ui;

    --button-size: 30px;
    --icon-width: var(--button-size);
    --icon-scale: 1;
    --calculated-icon-width: calc(var(--icon-scale) * var(--icon-width));

    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--button-size);
    height: var(--button-size);
    cursor: pointer;

    @at-root .video-container.large & {
      --button-size: 40px;
      --icon-scale: 1.25;
    }

    svg {
      height: auto;
      width: var(--calculated-icon-width);
    }

    &.controls-play-pause {
      --icon-width: 10px;
      margin-left: calc((var(--button-size) - var(--calculated-icon-width)) / -2);

      @at-root .video-container.ended & {
        --icon-width: 15px;
      }
    }

    &.controls-fullscreen {
      --icon-width: 16px;
      margin-right: calc((var(--button-size) - var(--calculated-icon-width)) / -2);
    }

    &.controls-muted {
      --icon-width: 17px;
    }
  }

  .controls-settings {
    grid-row: 2 / span 1;
    grid-column: 2 / span 1;
    justify-self: flex-end;
  }
}
</style>
